import React from 'react'
import { Link as LinkComponent } from 'gatsby'
import styled, { css } from 'styled-components'
import { object } from 'prop-types'
import media from 'styled-media-query'

import SubCategoryContext from '@/context/SubCategoryContext'

import { SECTIONS } from '@/helpers/mocks/home'
import { CATEGORIES } from '@/helpers/mocks/template'

import Layout, { TopBar, Footer } from '@/components/Layout'

import constructions from '@/assets/images/bg-header-constructions.svg'
import conversions from '@/assets/images/bg-header-conversions.svg'
import everydayLife from '@/assets/images/bg-header-everyday-life.svg'
import financeCalculators from '@/assets/images/bg-header-finance-calculator.svg'
import gradeCalculators from '@/assets/images/bg-header-grade-calculators.svg'
import healthAndFitness from '@/assets/images/bg-header-health-and-fitness.svg'
import mathCalculators from '@/assets/images/bg-header-math-calculators.svg'
import onlineTools from '@/assets/images/bg-header-online-tools.svg'

import { Tooltip } from '@/style-guide'

import Header from './Header'
import Sidebar from './Sidebar'
import CalculatorsList from './CalculatorsList'
import SubCategoriesMenu from './SubCategoriesMenu'
import InformationCard from './InformationCard'

const backgrounds = {
  constructions,
  conversions,
  'everyday-life': everydayLife,
  'finance-calculator': financeCalculators,
  'grade-calculators': gradeCalculators,
  'health-and-fitness': healthAndFitness,
  'math-calculators': mathCalculators,
  'online-tools': onlineTools
}

const Container = styled.div`
  background-color: #f7f7f7;
  width: 100%;
`

const Content = styled.div`
  padding-bottom: 40px;

  ${media.greaterThan('medium')`
    margin-top: 0px;
  `}
`

const PageContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 0 15px;

  ${media.greaterThan('medium')`
    padding: 0 30px;
    flex-direction: row;
    padding: 0 300px;
  `}
`

const NavBar = styled.div`
  ${media.greaterThan('medium')`
    display: flex;
  `}

  display: none;
  position: absolute;
  top: 65px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  height: 71px;
  padding: 0px 100px;
  background: #f6f6f6;
`

const Link = styled(LinkComponent)`
  text-decoration: none;
`

const Option = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 26px;
  letter-spacing: 3px;
  padding: 0px 5px;
  color: ${props => (props.active ? '#4472de' : '#c4c4c4')};
  cursor: pointer;
  transition: 0.5s;
  text-decoration: none;

  &:hover {
    color: #4472de;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;

      &:hover {
        color: #c4c4c4;
      }
    `}
`

const CalculatorCategory = ({ location: { pathname } }) => {
  const parts = pathname.split('/')

  let category
  let calculatorsList

  if (parts.length === 4) {
    category = parts[parts.length - 2]
  } else {
    category = parts[parts.length - 1]
  }

  const { subCategories, bgImage, title, calculators } = CATEGORIES[category] || {}

  return (
    <SubCategoryContext.Consumer>
      {value => {
        if (subCategories && subCategories[value.subCategory]) {
          calculatorsList = subCategories[value.subCategory]?.calculators || []
        } else {
          calculatorsList = calculators || []
        }

        return (
          <Layout>
            <Container>
              <TopBar />
              <SubCategoriesMenu
                sideCategories={subCategories}
                subActive={value.subCategory}
                category={category}
              />
              <NavBar>
                {SECTIONS.map((item, index) =>
                  item.disabled ? (
                    <Tooltip
                      content={
                        <Option
                          active={item.idSection === category ? 1 : 0}
                          key={index}
                          disabled={item.disabled}
                        >
                          {item.title.toUpperCase()}
                        </Option>
                      }
                      tooltipText="Coming soon"
                    />
                  ) : (
                    <Link to={`/calculator-categories/${item.idSection}`}>
                      <Option
                        active={item.idSection === category ? 1 : 0}
                        key={index}
                        disabled={item.disabled}
                      >
                        {item.title.toUpperCase()}
                      </Option>
                    </Link>
                  )
                )}
              </NavBar>
              <Content>
                <Header
                  title={title}
                  description="Congratulations, you just found the most random collection of calculators available on the Internet! Are you a hardcore geek? We’ll help you pick a motor for your drone. A travel junkie? Use our calculator to choose the optimal sunscreen SPF for holidays in Bali. A keen photographer?"
                  background={backgrounds[bgImage]}
                />
                <PageContainer>
                  <Sidebar
                    sideCategories={subCategories}
                    sideHeader={bgImage}
                    category={category}
                  />
                  {calculatorsList.length ? (
                    <CalculatorsList calculators={calculatorsList} category={category} />
                  ) : (
                    <div>
                      <InformationCard />
                    </div>
                  )}
                </PageContainer>
              </Content>
              <Footer />
            </Container>
          </Layout>
        )
      }}
    </SubCategoryContext.Consumer>
  )
}

CalculatorCategory.propTypes = {
  location: object
}

export default CalculatorCategory
