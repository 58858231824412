import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { string, node } from 'prop-types'

import { white } from '@/style-guide/colors'

const Container = styled.div`
  background-image: url(${({ background }) => background});
  background-size: cover;
  padding-top: 180px;
  min-height: 288px;
  width: 100%;
  margin-bottom: 15px;
  transform-origin: top left;
  transform: skewY(-5deg);

  ${media.greaterThan('medium')`
    margin-bottom: 76px;
    padding-top: 150px;
    transform-origin: unset;
    transform: unset;
  `}
`

const Content = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 30px;
  transform-origin: top right;
  transform: skewY(5deg);

  ${media.greaterThan('medium')`
    transform-origin: unset;
    transform: unset;
  `}
`

const Title = styled.h2`
  margin: 0 0 15px 0;
  font-size: 36px;
  color: ${white};
`

const Text = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 26px;
  color: ${white};
  max-width: 715px;
`

const Header = ({ title, description, background }) => (
  <Container background={background}>
    <Content>
      <Title>{title}</Title>
      <Text>{description}</Text>
    </Content>
  </Container>
)

Header.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  background: node
}

export default Header
