import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { object, string } from 'prop-types'

import { white } from '@/style-guide/colors'

import constructions from '@/assets/images/bg-sidebar-constructions.svg'
import conversions from '@/assets/images/bg-sidebar-conversions.svg'
import everydayLife from '@/assets/images/bg-sidebar-everyday-life.svg'
import financeCalculators from '@/assets/images/bg-sidebar-finance-calculator.svg'
import gradeCalculators from '@/assets/images/bg-sidebar-grade-calculators.svg'
import healthAndFitness from '@/assets/images/bg-sidebar-health-and-fitness.svg'
import mathCalculators from '@/assets/images/bg-sidebar-math-calculators.svg'
import onlineTools from '@/assets/images/bg-sidebar-online-tools.svg'

import SubCategoryCard from '@/components/SubCategoryCard'

const backgrounds = {
  constructions,
  conversions,
  'everyday-life': everydayLife,
  'finance-calculator': financeCalculators,
  'grade-calculators': gradeCalculators,
  'health-and-fitness': healthAndFitness,
  'math-calculators': mathCalculators,
  'online-tools': onlineTools
}

const Container = styled.div`
  background-color: ${white};
  box-shadow: 4px 10px 12px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;
  bottom: 55px;

  ${media.greaterThan('medium')`
    margin-right: 48px;
    width: 380px;
    height: fit-content;
    position: unset;
  `}
`

const Cover = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(62.97deg, #4371dd 31.79%, #92e7f2 97.12%);
  border-radius: 4px;

  ${media.greaterThan('medium')`
    width: 380px;
    height: 276px;
  `}
`

const Content = styled.div`
  padding: 20px;
  display: none;

  ${media.greaterThan('medium')`
    display: block;
  `}
`

const CardTitle = styled.span`
  font-weight: bold;
  font-size: 12.8602px;
  line-height: 27px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: #3b58a8;
  margin-left: 10px;
`

const Sidebar = ({ sideCategories = {}, sideHeader, category }) => (
  <Container>
    <Cover>
      <img src={backgrounds[sideHeader]} alt="category-sidebar" />
    </Cover>
    <Content>
      <CardTitle>Category</CardTitle>
      {Object.values(sideCategories).map((item, index) => (
        <SubCategoryCard
          key={index}
          icon={item.icon}
          label={item.label}
          id={item.id}
          category={category}
          disabled={item.disabled}
        />
      ))}
    </Content>
  </Container>
)

Sidebar.propTypes = {
  sideCategories: object,
  sideHeader: string,
  category: string
}

export default Sidebar
