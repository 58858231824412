import React from 'react'
import { Link as LinkComponent } from 'gatsby'
import styled from 'styled-components'
import media from 'styled-media-query'
import { node, array, string } from 'prop-types'

import arrowRight from '@/assets/icons/arrow-right.svg'

import CardComponent from '@/style-guide/Card'
import { black } from '@/style-guide/colors'

const Container = styled.div``

const Link = styled(LinkComponent)`
  text-decoration: none;
  color: ${black};
`

const Card = styled(CardComponent)`
  width: 100%;
  border: none;
  margin-bottom: 30px;
  padding: 10px 25px;
  border-radius: 4px;
  min-width: unset;
  cursor: pointer;
  box-shadow: 4px 10px 12px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out;

  :hover {
    box-shadow: 10px 16px 18px rgba(0, 0, 0, 0.05);
  }

  ${media.greaterThan('medium')`
    width: 478px;
    height: 138px;
  `}
`

Card.propTypes = {
  children: node
}

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
`

const CardTitle = styled.h3`
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  text-transform: capitalize;
  color: #092c4c;
  margin: 0px;
`

const CardContent = styled.p`
  font-size: 12px;
  line-height: 22px;
  color: #7f92a3;
  opacity: 0.7;
  margin: 0;
`

const CalculatorsList = ({ calculators = [], category }) => (
  <Container>
    {calculators.map(({ id, title, description }, index) => (
      <Link key={index} to={`/calculators/${category}/${id}`}>
        <Card
          key={id}
          styleContent={{ padding: '0px' }}
          content={
            <>
              <CardHeader>
                <CardTitle>{title}</CardTitle>
                <img src={arrowRight} alt="Arrow right" />
              </CardHeader>
              <CardContent>{description}</CardContent>
            </>
          }
        />
      </Link>
    ))}
  </Container>
)

CalculatorsList.propTypes = {
  calculators: array,
  category: string
}

export default CalculatorsList
