import React, { useState } from 'react'
import styled from 'styled-components'
import { object, string } from 'prop-types'
import media from 'styled-media-query'

import { Card as CardComponent } from '@/style-guide'

import menuBars from '@/assets/icons/menu-bars.svg'

import SubCategoryCard from '@/components/SubCategoryCard'

const Card = styled(CardComponent)`
  position: absolute;
  top: 75px;
  width: 90%;
  right: 5%;
  min-width: unset;
  border: unset;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 3.67436px 9.18589px 11.0231px rgba(0, 0, 0, 0.05);

  ${media.greaterThan('medium')`
    display: none;
  `}
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
  padding: 0px 12px;
`

const CardTitle = styled.span`
  font-weight: bold;
  font-size: 12.8602px;
  line-height: 27px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: #3b58a8;
`

const Content = styled.div``

const SubCategoriesMenu = ({ sideCategories = [], category, subActive }) => {
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(sideCategories[subActive])

  const handleVisible = () => {
    setVisible(!visible)
  }

  const handleSelected = item => {
    setSelected(item)
    handleVisible()
  }

  return (
    <Card
      styleContent={{ borderRadius: '4px' }}
      content={
        <>
          <CardHeader onClick={handleVisible}>
            <CardTitle>Category</CardTitle>
            <img src={menuBars} alt="Menu bars" />
          </CardHeader>
          {visible ? (
            <Content>
              {Object.values(sideCategories).map((item, index) => (
                <SubCategoryCard
                  key={index}
                  icon={item.icon}
                  label={item.label}
                  handleClick={() => handleSelected(item)}
                  id={item.id}
                  category={category}
                  disabled={item.disabled}
                />
              ))}
            </Content>
          ) : (
            <SubCategoryCard
              key={99}
              selected
              icon={selected?.icon}
              label={selected ? selected.label : 'Select a category'}
            />
          )}
        </>
      }
    />
  )
}

SubCategoriesMenu.propTypes = {
  sideCategories: object,
  category: string,
  subActive: string
}

export default SubCategoriesMenu
