import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import CardComponent from '@/style-guide/Card'

const Card = styled(CardComponent)`
  width: 100%;
  border: none;
  margin-bottom: 30px;
  padding: 10px 25px;
  border-radius: 4px;
  min-width: unset;
  cursor: pointer;
  box-shadow: 4px 10px 12px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out;

  :hover {
    box-shadow: 10px 16px 18px rgba(0, 0, 0, 0.05);
  }

  ${media.greaterThan('medium')`
    width: 478px;
    height: fit-content;
  `}
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Message = styled.span`
  text-align: center;
`

const InformationCard = () => (
  <Card
    content={
      <Content>
        <Message>As soon as possible we&apos;ll be able to make more calculators</Message>
      </Content>
    }
  />
)

export default InformationCard
